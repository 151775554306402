export const CourseData = [
  {
    id: "advice-guidance-course",
    title: "Levels 1–3 Certificate in Advice & Guidance",
    description:
      "This course is designed for those working directly with clients, providing advice and guidance across various sectors.",
    mainWebContent: [
      {
        heading: "Who is it for?",
        content: (
          <p>
            To achieve this qualification, you will be working directly with
            clients disseminating information, advice, and some level of
            guidance. You will be:
            <ul>
              <li>
                working to clear guidelines in the practice of advice and
                guidance
              </li>
              <li>
                working in designated advice and/or guidance organisations
              </li>
              <li>providing information that is often interpreted by others</li>
            </ul>
          </p>
        ),
      },
      {
        heading: "What topics does the qualification cover?",
        content: (
          <p>
            <strong>
              Establish communication with clients for advice and guidance:
            </strong>{" "}
            <br />
            communicating with clients using a range of methods, minimising
            difficulties and barriers to communication.
            <br />
            <br />
            <strong>
              Support clients to make use of the advice and guidance service:
            </strong>{" "}
            <br />
            identifying and providing accurate information to meet client’s
            needs, understanding other services provided.
            <br />
            <br />
            <strong>Review own contribution to the service:</strong> reviewing
            own performance against development objectives, agreeing on a
            personal development plan.
            <br />
            <br />
            <strong>
              Understand the importance of legislation and procedures:
            </strong>{" "}
            <br />
            demonstrate understanding and compliance with legislation, codes of
            practice and organisational requirements. Use organisational systems
            to record interactions.
          </p>
        ),
      },
      {
        heading: "Programme structure",
        content: (
          <p>
            You will provide evidence of your work practice, including
            completing assignments, case studies, written accounts, and
            providing work products. Evidence is uploaded to an e-portfolio
            which is available 24/7 on computers, tablets, and mobile devices.
            <br />
            An Open University assessor will observe work-based practice on at
            least one occasion, assess evidence uploaded to the e-portfolio, and
            provide developmental feedback.
          </p>
        ),
      },
      {
        heading: "Support",
        content: (
          <p>
            You will have access through VQLearner Home to online resources
            specific to your qualification and links to suggested further study
            with The Open University.
            <br />
            Support will be provided throughout the qualification by the VQAC
            Learner Support Team.
          </p>
        ),
      },
    ],
    summaryWebContent: [
      {
        label: "Duration",
        value: "12 months",
      },
      {
        label: "Credit Value",
        value: "21 credits",
      },
      {
        label: "Total Qualification Time",
        value: "210 hours",
      },
      {
        label: "Fee",
        value: "£2000",
      },
      {
        label: "Further Information",
        value: <a href="#">Download brochure</a>,
      },
      {
        label: "Standards",
        value: "Quality assured by industry standards.",
      },
    ],
  },
];
