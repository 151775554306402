import React from "react";

import "./CoursePageLeftColumn.css"

function CoursePageLeftColumn({ sections }) {
  return (
    <div className="left-body-content">
      {sections.map((section, index) => (
        <div key={index} className="course-section">
          <h2 className="course-section-heading">{section.heading}</h2>
          <div className="course-section-content">{section.content}</div>
        </div>
      ))}
      <hr />
    </div>
  );
}

export default CoursePageLeftColumn;