import React from "react";
import { useParams } from "react-router-dom";
import { CourseData } from "./CourseData"; // Corrected import

import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import CoursePageLeftColumn from "./CoursePageLeftColumn/CoursePageLeftColumn";
import CoursePageRightColumn from "./CoursePageRightColumn/CoursePageRightColumn";


import "./CoursePage.css";

function CoursePage() {
  const { id: courseId } = useParams(); // Getting courseId from the URL
  console.log(courseId); // Check if the id is coming from the URL

  const course = CourseData.find((course) => course.id === courseId); // Using CourseData to find course

  if (!course) {
    // Handle case where course is not found
    return <p>Course not found</p>;
  }

  return (
    <div>
      <Navbar />
      <main className="course-page-wrapper">
        <div className="course-page">
          <div className="heading-wrapper">
            <h1 className="course-page-heading">{course.title}</h1>
          </div>
          <div className="main-body">
            <CoursePageLeftColumn sections={course.mainWebContent} />
            <CoursePageRightColumn summaryContent={course.summaryWebContent} />
          </div>

          <div className="next-steps-div">
            <h3>Next Steps</h3>
            <p>
              If you are interested in this qualification, find out how to
              register by contacting us.
            </p>

            <div className="button-container">
              <a href="/contact" className="contact-button">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default CoursePage;
