import React, { Component } from "react";
import {Link} from 'react-router-dom';
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";
import { NavbarItems } from "./NavbarItems"
import './Navbar.css'
import caret from '../../images/svg/icon-down-caret.svg'


class Navbar extends Component {
  constructor(props) {
    super(props);
    this.clickCallbackReceiver = this.clickCallbackReceiver.bind(this);
    this.state = {
      clicked: false
    };
  }

  clickCallbackReceiver = (clickedValue) => {
    this.setState({ clicked: clickedValue });
  };

  render() {
    const clicked = this.state.clicked;
    return (
      <nav className="NavbarItems">
        <a href="/">
          <img
            className={
              this.state.clicked ? "navbar-logo active" : "navbar-logo"
            }
            src={require(`../../images/APT-Logo.png`)}
            alt="APT logo"
          />
        </a>
        <div className="menu-icon">
          <HamburgerMenu
            state={clicked}
            setState={this.clickCallbackReceiver}
          />
        </div>
        <div className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
          <ul>
            {NavbarItems.map((item, index) => {
              let subnav_classname = "subnav-container";

              item.subnavitems.map((childItem) => {
                if (childItem.category !== "") {
                  subnav_classname = "subnav-container has-categories";
                }
                return null;
              });

              return (
                <li className="nav-links-container">
                  <Link
                    className={item.className}
                    to={item.url}
                    onClick={() => window.location.href(item.url)}
                  >
                    {item.title}
                    {item.subnavitems.length > 0 && (
                      <img alt="↓" src={caret}></img>
                    )}
                  </Link>
                  {
                    // if menu item has sub-nav items, creates sub-nav menu.
                    item.subnavitems.length > 0 && (
                      <span className={subnav_classname}>
                        {item.subnavitems.map((childItem, index) => {
                          return (
                            <div
                              className={
                                childItem.category === ""
                                  ? ""
                                  : "subitem-category-container"
                              }
                            >
                              <p className="category-title">
                                {childItem.category}
                              </p>
                              {childItem.items.map((cItemContent, index) => {
                                return (
                                  <Link
                                    className={cItemContent.className}
                                    to={cItemContent.url}
                                    onClick={() =>
                                      window.location.href(cItemContent.url)
                                    }
                                  >
                                    {cItemContent.title}
                                  </Link>
                                );
                              })}
                            </div>
                          );
                        })}
                      </span>
                    )
                  }
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navbar;