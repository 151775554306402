import React from "react";
import "./CoursePageRightColumn.css";

function CoursePageRightColumn({ summaryContent = [] }) {
  return (
    <div className="right-body-content">
      <div className="course-summary">
        <h3>Course Summary</h3>
      </div>
      <div className="content-wrapper">
        {summaryContent.map((item, index) => (
          <div key={index} className="summary-item">
            <h4>
              <strong>{item.label}:</strong>
            </h4>
            <p>{item.value}</p>
          </div>
        ))}

        {/* Rounded button */}
        <div className="button-container">
          <a href="/contact" className="rounded-button">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
}

export default CoursePageRightColumn;
